:root {
    --color-1: #0C2545;
    --color-2: #2a598f;
    --color-3: #961c20;
    --color-4: #20961c;
    --color-5: #fbdc93;
    --header-font: 'Work Sans', sans-serif;
    --sub-header: 'Yantramanav', sans-serif;
    --paragraph: 'Montserrat', sans-serif;
    --serif: 'Martel', serif;
}


.about-header {
    background-color: var(--color-1);
    text-align: center;

}

#about-header-image {
    width: 100%;
    height: 0;
    padding-top: 45%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;

}


#between {
    margin: 0.1em 0;
}

.about-header h1 {
    font-family: var(--header-font);
    color: white;
    font-size: 4em;
    padding: 0.5em 0 0 0;
}

.who-are-we {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1em;
    margin: 2em 0;
}

.who-are-we h4 {
    font-family: var(--sub-header);
    font-size: 2em;
    color: var(--color-1);
}

.who-are-we p {
    font-family: var(--paragraph);
    width: 75%;
    line-height: 1.5;
}

#who-are-we-line {
    width: 20%;
    height: 3px;
    background-color: var(--color-5);
    margin-bottom: 1em;
}


#what-we-offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-1);
    padding: 6em 1em;
}

#what-we-offer h4 {
    font-family: var(--sub-header);
    font-size: 3em;
    color: white;
    margin-bottom: 0.25em;
}

#what-we-offer-line {
    width: 40%;
    height: 3px;
    background-color: white;
    margin-bottom: 5em;
}


#about-cards {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.about-card {
    width: 32%;
    min-height: 400px;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    justify-content: center;
    padding: 1em;
    text-align: center;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
}

.about-card p {
    font-family: var(--paragraph);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.about-card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3em;
}

.about-card-title h3 {
    font-family: var(--sub-header);
    font-size: 2em;
    margin-top: 0.5em;
    color: var(--color-2)
}

.about-card button {
    background-color: var(--color-4);
    color: white;
    border: none;
    margin-top: 2em;
    width: 40%;
    align-self: center;
    height: 25px;
    border-radius: 1px;
    cursor: pointer;
}


.about-card button:hover {
    background-color: var(--color-2);
}

.abt-crd-line {
    width: 40%;
    height: 6px;
    background-color: var(--color-5);
    margin-top: 0.25em;
}


@media screen and (max-width: 1200px) {
    .abt-crd-line {
        width: 60%;
    }

    #what-we-offer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--color-1);
        padding: 6em 0.5em;
    }
}


@media screen and (max-width: 992px) {
    .about-header h1 {
        font-size: 3em;
    }

    #who-are-we-line {
        width: 40%;
    }

    #what-we-offer-line {
        width: 60%;
    }

    .abt-crd-line {
        width: 70%;
    }
}



@media screen and (max-width: 768px) {
    #about-cards {
        display: flex;
        flex-direction: column;
        width: 95%;
        align-items: center;
    }

    .about-card {
        width: 90%;
        min-height: 400px;
        background-color: white;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        justify-content: center;
        padding: 1em;
        text-align: center;
        margin-bottom: 1em;
    }

    .who-are-we p {
        font-family: var(--paragraph);
        width: 95%;
        line-height: 1.5;
    }

    .about-header h1 {
        font-size: 2.5em;
    }

    #who-are-we-line {
        width: 60%;
    }

    #what-we-offer-line {
        width: 70%;
    }

    .abt-crd-line {
        width: 40%;
    }


    #what-we-offer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--color-1);
        padding: 3em 0.25em;
    }
}


@media screen and (max-width: 576px) {
    #what-we-offer-line {
        width: 85%;
    }

    #what-we-offer h4 {
        font-size: 2.5em;
    }


    .about-card {
        width: 95%;
    }
}


@media screen and (max-width: 400px) {

    #about-cards {
        width: 98%;
    }

    .about-header h1 {
        font-size: 2em;
    }

    #what-we-offer h4 {
        font-size: 2em;
    }

    #who-are-we-line {
        width: 80%;
    }

    #what-we-offer-line {
        width: 90%;
    }

    .abt-crd-line {
        width: 70%;
    }

    .about-card {
        width: 100%;
    }
}


@media screen and (max-width: 300px) {
    .abt-crd-line {
        width: 90%;
    }
}
