:root {
    --color-1: #0C2545;
    --color-2: #2a598f;
    --color-3: #961c20;
    --color-4: #20961c;
    --color-5: #fbdc93;
    --header-font: 'Work Sans', sans-serif;
    --sub-header: 'Yantramanav', sans-serif;
    --paragraph: 'Montserrat', sans-serif;
    --serif: 'Martel', serif;
}


#tic-tac-toe-main {
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.winning-square path {
    fill: var(--color-5);
}

.winning-square polygon {
    fill: var(--color-5);
}


.winning-square {
    text-decoration: line-through;
}




.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;

}




/*home*/

#main-title {
    font-size: 3.5em;
    color: white;
    padding: 0.5em;

}

.main-home {
    background-color: var(--color-5);
}

/*Board*/

#board-body {

    position: relative;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 185px);
    grid-template-rows: repeat(3, 185px);
    padding: 1em;
}

#board-outer {

    width: 100%;

}


.animate-delay {
    transition: transform 1s ease-in-out;
}

.reset-button {
    width: 555px;
    align-self: center;
    border: none;
    background-color: var(--color-1);
    color: white;
    font-size: 1.5em;
    margin-bottom: 1em;
    margin-top: 0.5em;
    height: 1.5em;

}

.reset-button:hover {
    background-color: var(--color-3);
}

.whos-turn {
    font-size: 3em;
    color: white;
    margin-bottom: 2em;
}



.windisplay {
    color: var(--color-1);
    font-size: 2.5em;
    margin-bottom: 1em;
}




.mode-section {
    display: flex;
    gap: 1em;
    align-items: center;
    margin: 0 auto;
}

.mode-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    cursor: pointer;
    width: 300px;
    background-color: var(--color-2);
    height: 55px;

}

#modes h2 {
    font-size: 3em;
    font-family: var(--header-font);
    color: var(--color-1);
    text-align: center;
    margin-bottom: 2em;
}

.mode-btn p {
    font-family: var(--sub-header);
    color: white;
}

.mode-btn:hover {
    background-color: var(--color-3);
}



.modes-change {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 555px;
}

.mode-btn-2 {
    display: flex;
    align-items: center;
    background-color: var(--color-2);
    justify-content: center;
    width: 48%;
    height: 30px;
    gap: 0.5em;
}

.mode-btn-2 p {
    font-family: var(--sub-header);
    color: white;
}

.mode-btn-2:hover {
    background-color: var(--color-3);
}

.active-mode-btn {
    background-color: var(--color-3);
}

/*square*/

.square {
    width: 185px;
    height: 185px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}


.square:nth-child(1) {
    border-bottom: 15px solid var(--color-2);
}

.square:nth-child(2) {
    border-bottom: 15px solid var(--color-2);
    border-right: 15px solid var(--color-2);
    border-left: 15px solid var(--color-2);
}

.square:nth-child(3) {
    border-bottom: 15px solid var(--color-2);
}

.square:nth-child(5) {
    border-right: 15px solid var(--color-2);
    border-left: 15px solid var(--color-2);
}

.square:nth-child(7) {
    border-top: 15px solid var(--color-2);
}

.square:nth-child(8) {
    border-top: 15px solid var(--color-2);
    border-right: 15px solid var(--color-2);
    border-left: 15px solid var(--color-2);
}

.square:nth-child(9) {
    border-top: 15px solid var(--color-2);
}



.svg-size {
    width: 100px;
    height: 100px;
}

.o path {
    fill: var(--color-3);

}

.x path {
    fill: var(--color-1);

}



/*win line */


.win-streak-1 {
    position: absolute;
    background-color: white;
    right: 0px;
    left: 0px;
    top: 17%;
    height: 10px;
    z-index: 200;
    border-radius: 10px;
}

.win-streak-2 {
    position: absolute;
    background-color: white;
    right: 0px;
    left: 0px;
    top: 50%;
    height: 10px;
    z-index: 200;
    border-radius: 10px;
}

.win-streak-3 {
    position: absolute;
    background-color: white;
    right: 0px;
    left: 0px;
    bottom: 17%;
    height: 10px;
    z-index: 200;
    border-radius: 10px;
}

.win-streak-4 {
    position: absolute;
    background-color: white;
    top: 0px;
    bottom: 0px;
    left: 17%;
    width: 10px;
    z-index: 200;
    border-radius: 10px;
}

.win-streak-5 {
    position: absolute;
    background-color: white;
    top: 0px;
    bottom: 0px;
    left: 50%;
    width: 10px;
    z-index: 200;
    border-radius: 10px;
}

.win-streak-6 {
    position: absolute;
    background-color: white;
    top: 0px;
    bottom: 0px;
    right: 17%;
    width: 10px;
    z-index: 200;
    border-radius: 10px;
}


.win-streak-7 {
    position: absolute;
    width: 90%;
    height: 10px;
    top: 0;
    left: 0;
    background-color: white;
    transform-origin: top right;
    transform: translateY(50%) skewY(-45deg);


}


.win-streak-8 {

    position: absolute;
    width: 90%;
    height: 10px;
    top: 0;
    left: 0;
    background-color: white;
    transform-origin: top left;
    transform: translateY(50%) skewY(45deg);

}

/*responsiveness*/

@media screen and (max-width: 992px) {
    .windisplay {
        color: var(--color-1);
        font-size: 1.5em;
        margin-bottom: 0.75em;
    }
}

@media screen and (max-width: 768px) {
    #board-body {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 120px);
        grid-template-rows: repeat(3, 120px);
        padding: 1em;
    }

    #main-title {
        font-size: 2.5em;
    }

    .square {
        width: 120px;
        height: 120px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }

    .reset-button {
        width: 360px;
    }

    .modes-change {
        width: 360px;
    }


    .svg-size {
        width: 75px;
        height: 75px;
    }

    .mode-section {
        flex-direction: column;

    }


}

@media screen and (max-width: 576px) {
    #modes h2 {
        font-size: 2.5em;
    }
}

@media screen and (max-width: 380px) {
    #board-body {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 90px);
        grid-template-rows: repeat(3, 90px);
        padding: 0.25em;
    }

    #main-title {
        font-size: 2em;
    }

    .square {
        width: 90px;
        height: 90px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }

    .reset-button {
        width: 270px;
    }

    .modes-change {
        width: 270px;
        flex-direction: column;
    }

    .mode-btn-2 {
        width: 100%;
    }




    .svg-size {
        width: 55px;
        height: 55px;
    }

    .mode-btn {
        width: 270px;
    }

    #modes h2 {
        font-size: 2em;
    }
}


@media screen and (max-width: 300px) {
    #board-body {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 75px);
        grid-template-rows: repeat(3, 75px);
        padding: 0.25em;
    }

    #main-title {
        font-size: 2em;
    }

    .square {
        width: 75px;
        height: 75px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }

    .reset-button {
        width: 225px;
    }

    .svg-size {
        width: 40px;
        height: 40px;
    }



}