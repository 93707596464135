:root {
  --color-1: #0C2545;
  --color-2: #2a598f;
  --color-3: #961c20;
  --color-4: #20961c;
  --color-5: #fbdc93;
  --header-font: 'Work Sans', sans-serif;
  --sub-header: 'Yantramanav', sans-serif;
  --paragraph: 'Montserrat', sans-serif;
  --serif: 'Martel', serif;
}


button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.App {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}


.content {
  flex: 1;

}

footer {
  justify-self: flex-end;
  width: 100%;
  height: 120px;
}

.footer-top {
  background-color: var(--color-1);
  width: 100%;
  padding: 3em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-top h3 {
  color: white;
  font-family: var(--header-font);
  margin-bottom: 0.5em;
}

.footer-top p {
  color: white;
  font-family: var(--sub-header);
  margin-bottom: 0.25em;
}

.x-icon {
  width: 15px;
}

.x-icon-fill {
  fill: white;
  cursor: pointer;
}

.x-icon:hover>.x-icon-fill {
  fill: var(--color-4);
}



#our-sites {
  width: 50%;
  text-align: center;
}

#socials {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.footer-bottom {
  /* background-color: #2d4760; */
  background-color: var(--color-1);
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bottom p {
  color: white;
  font-size: 9px;
}

#logo-footer {
  margin-right: 15px;

}

#title-footer {
  margin-left: 15px;
  color: white;
  font-size: 2em;
}

.social-icons {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.social-icon {
  color: white;
}

.social-icon:hover {
  color: var(--color-4);
}

.footer-line {
  width: 90%;
  height: 3px;
  background-color: white;
  margin-bottom: 1em;
}

.image-banner {
  display: flex;
  height: 100px;
}

.image-banner-img {
  width: 16.66%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

/*header*/

/*header for mobile */

#mobile-header {
  padding: 0.5em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#hamburger-menu {
  display: flex;
  flex-direction: column;
  height: 25px;
  justify-content: space-between;

}

#hamburger-menu:hover>div {
  background-color: var(--color-4);
}

#hamburger-menu div {
  width: 35px;
  height: 4px;
  background-color: var(--color-1);
  border-radius: 4px;

}


#mobileNav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  background-color: var(--color-1);
  z-index: 500;
  height: 100vh;

}





/* .mobile-dropdown::-webkit-scrollbar {
  width: 6px;

}

.mobile-dropdown::-webkit-scrollbar-thumb {
  background-color: #888;

} */

.mob-nav {
  height: 90%;
}

.nav-links-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 90%;
  align-items: center;
}

.link-mobile {
  color: white;
  text-decoration: none;
}

.nav-x-btn-container {
  /* position: fixed;
  top: 20px;
  right: 65%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  /* padding-right: 2px; */
  z-index: 101;
}

.x-and-logo {
  display: flex;
  align-items: center;
  padding: 2em;
  justify-content: space-between;
}

.nav-x-btn-container div {
  background-color: white;
  width: 2px;
  height: 20px;
}

.nav-x-btn-container:hover>div {
  background-color: #427693;
}

.nav-x-btn-container :nth-child(1) {
  transform: translatex(2px) rotate(45deg);
}

.nav-x-btn-container :nth-child(2) {
  transform: rotate(-45deg);
}

/*desktop header*/

#desktop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 3em;

}



.nav-links {
  display: flex;
  justify-content: space-between;
}

.desktop-nav {
  width: 60%;
}

.nav-link {
  font-size: 16px;
  color: var(--color-2);
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.nav-link:hover {
  color: var(--color-3);
}









.game-controller-btn:hover {
  border-bottom: 2px solid var(--color-4);
}

.game-controller-btn p {
  margin-left: 0.25em;
  font-family: var(--sub-header);
  font-weight: 700;
  color: var(--color-2);
}

#home-nav-link {
  font-family: var(--sub-header);
  font-weight: 700;
  color: var(--color-2);
}

#about-nav-link {
  font-family: var(--sub-header);
  font-weight: 700;
  color: var(--color-2);
}

.tab-icon {
  height: 20px;
}


.active-tab {
  border-bottom: 2px solid var(--color-5);
}

.mobile-nav-link {
  color: white;
  text-decoration: none;
  font-family: var(--sub-header);
  cursor: pointer;
}







.nav-link-mobile-wicon {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 105px;
  justify-content: flex-start;
  padding: 0.2em 0;
  border-bottom: 1px solid var(--color-1);
}

.nav-link-mobile-active {
  border-bottom: 1px solid var(--color-5);
}


















.articles-mobile {
  background-color: var(--color-2);
  position: absolute;
}








@media screen and (max-width: 992px) {
  /* .accoladi-logo {
    width: 60%;
  }

  .accoladi-banner p {
    font-size: 22px;
    width: 70%;
  } */

  .desktop-nav {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {



  .footer-top {
    padding: 3em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }



  #our-sites {
    width: 80%;
    text-align: center;
  }

  #socials {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-bottom: 2em;
  }

  .image-banner {
    height: 75px;
  }

  .image-banner-img {
    height: 75px;
  }


  ::-webkit-scrollbar-vertical {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  /* .accoladi-logo {
    width: 85%;
  }

  .accoladi-banner p {

    width: 95%;
  } */

  .image-banner {
    height: 60px;
  }

  .image-banner-img {
    height: 60px;
  }
}

@media screen and (max-width: 400px) {

  .image-banner {
    height: 50px;
  }

  .image-banner-img {
    height: 50px;
  }
}