:root {
    --color-1: #0C2545;
    --color-2: #2a598f;
    --color-3: #961c20;
    --color-4: #20961c;
    --color-5: #fbdc93;
    --header-font: 'Work Sans', sans-serif;
    --sub-header: 'Yantramanav', sans-serif;
    --paragraph: 'Montserrat', sans-serif;
    --serif: 'Martel', serif;
}

.main-home {
    background-color: var(--color-5);
    color: #e56b6f;

}


.trivia-main {
    background-color: #efefef;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.triv-perfect-score {
    display: none !important;
}

#trivia-outer {

    width: 100%;
    background-size: cover;
    background-position: center;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.perfect-win-title {
    font-size: 3em;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: var(--header-font);
}

.TriviaGame {
    text-align: center;
    background-color: #355070;

    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #000;
    width: 60%;
    margin: 0 auto;
    min-height: 400px;
}

h1 {
    font-size: 28px;
    color: #ff4500;
    /* A shade of orange */
    margin-bottom: 20px;
}

.Question {
    font-size: 20px;
    margin: 10px 0;
    align-self: center;
    align-content: flex-end;
}

.AnswerOption {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AnswerOption li {
    background-color: white;
    color: black;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
    font-size: 16px;
    width: 80%;
}

.AnswerOption li:hover {
    background-color: var(--color-4);
    color: white;
}

.GameOverScreen {
    background-color: #333;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #000;
    max-width: 400px;
    margin: 0 auto;
}

.GameOverScreen h2 {
    font-size: 24px;
    margin: 0 0 10px;
}

@media screen and (max-width: 768px) {
    .TriviaGame {
        width: 80%;
    }
}

@media screen and (max-width: 450px) {
    .TriviaGame {
        width: 95%;
    }
}

.AnswerOptionBox {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid white;
    cursor: pointer;
}

.CorrectAnswerBox {
    background-color: green;
    color: white;
    padding: 10px;
    margin-top: 10px;
}